<template>
  <b-card header-tag="header" header-text-variant="primary">
    <!-- HEADER -->
    <template v-slot:header>
      <b-card-title>
        Categories
        <!-- New -->
        <b-icon-plus-circle @click="addNew" scale="1.5" class="float-right" />
      </b-card-title>
    </template>

    <MerchantCategory
      v-for="category of categories"
      :key="category.id"
      :category="category"
      class="mb-3"
    />
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import MerchantCategory from "../../../components/categories/MerchantCategory";

export default {
  components: {
    MerchantCategory,
  },
  computed: {
    ...mapGetters({
      categories: "merchant/categories/getList",
    }),
  },
  methods: {
    addNew() {
      this.$router.push({ name: "CategoryForm" });
    },
  },
};
</script>
