<template>
  <b-card>
    <b-card-title>
      {{ title }}

      <!-- Options -->
      <b-dropdown
        class="float-right"
        variant="link"
        toggle-class="text-decoration-none"
        right
        no-caret
      >
        <!-- Button Design -->
        <template #button-content>
          <b-icon-three-dots-vertical />
        </template>

        <!-- Edit Category -->
        <b-dropdown-item href="#" @click="onEditClick">
          Edit Category
        </b-dropdown-item>

        <!-- TODO: Share Category -->
        <!-- <b-dropdown-item href="#">Another item</b-dropdown-item> -->

        <!-- Delete Category -->
        <b-dropdown-item href="#" @click="onDeleteClick">
          Delete Category
        </b-dropdown-item>
      </b-dropdown>
    </b-card-title>

    <!-- TODO: set no of products in this category -->
    <!-- <p class="mb-0">{{ productCount }} Products listed</p> -->
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { deleteCategory } from "../../apis/merchant";
export default {
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: this.category.name,
      productCount: 1,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
  },
  methods: {
    ...mapActions({
      removeCategory: "merchant/categories/removeItem",
    }),
    onEditClick() {
      // redirect to form with category ID
      this.$router.push({
        name: "CategoryForm",
        params: { categoryId: this.category.id },
      });
    },
    onDeleteClick() {
      deleteCategory(this.category.id, this.token).then(() => {
        // remove from state, will automatically render
        this.removeCategory({ id: this.category.id });
      });
    },
  },
};
</script>